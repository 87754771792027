import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './modules/shared/not-found/not-found.component';
import { ExtrnalLinkLayoutComponent } from './extrnal-link-layout/extrnal-link-layout.component';
// import { TrialLayoutComponent } from './modules/medoc-trial-form/trial-layout/trial-layout.component';
import { ProviderResolver } from './core/resolvers/provider.resolver';
import { LayoutComponent } from './layout/layout/layout.component';
import { ProviderGuard } from './core/guards/provider.guard';
import { HomeGuard } from './core/guards/home.guard';
import { AuthGuard } from './core/guards/auth.guard';

const homeRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/modules/home/home.module').then((m) => m.HomeModule)
  }
  ,
  {
    path: 'for-patients',
    loadChildren: () =>
      import('src/app/modules/patients-landing/patients-landing.module').then(
        (m) => m.PatientsLandingModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('src/app/modules/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('src/app/modules/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'our-partners',
    loadChildren: () =>
      import('src/app/modules/providers/providers.module').then(
        (m) => m.ProvidersModule
      ),
  },
  {
    path: 'doctors',
    loadChildren: () =>
      import('src/app/modules/doctors/doctors.module').then(
        (m) => m.DoctorsModule
      ),
  },
  {
    path: 'solution',
    children: [
      {
        path: 'clinic-management-system',
        loadChildren: () => import('src/app/modules/clinic-management/clinic-management.module').then(m => m.ClinicManagementModule)
      },
      {
        path: 'نظام-إدارة-العيادات',
        loadChildren: () => import('src/app/modules/clinic-management/clinic-management.module').then(m => m.ClinicManagementModule)
      },
      {
        path: 'business',
        loadChildren: () => import('src/app/modules/business/business.module').then(m => m.BusinessModule)
      },
    ]
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'policies',
    loadChildren: () => 
      import('./modules/content-pages/content-pages.module').then((m) => m.ContentPagesModule),
  },
];

const providerRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/modules/provider-info/provider-info.module').then((m) => m.ProviderInfoModule)
  }
];

const externalRoutes: Routes = [
  {
    path: 'prescription',
    loadChildren: () => import('./modules/prescription/prescription.module').then((m) => m.PrescriptionModule),
  },
  {
    path: 'reviews',
    loadChildren: () => import('./modules/review/review.module').then((m) => m.ReviewModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/patient-report/patient-report.module').then((m) => m.PatientReportModule),
  },
]

export const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  // {
  //   path: 'get-started',
  //   component: TrialLayoutComponent,
  //   loadChildren: () => import('./modules/medoc-trial-form/medoc-trial-form.module').then(m => m.MedocTrialFormModule)
  // },
  {
    path: '',
    component: LayoutComponent,
    children: homeRoutes,
    canActivate: [HomeGuard]
  },
  {
    path: '',
    resolve: { provider: ProviderResolver },
    children: providerRoutes,
    canActivate: [ProviderGuard]
  },
  {
    path: '',
    component: ExtrnalLinkLayoutComponent,
    children: externalRoutes
  },
  {
    path: 'org',
    loadChildren: () =>
      import('src/app/modules/provider-info/provider-info.module').then((m) => m.ProviderInfoModule)
  },
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }